.button {
    border: none;
    background: radial-gradient(150% 200% at 90% -10%, #30aed6 30%, #2a57d9);
    border-top: 1px solid #66c6e4;
    border-bottom: 1px solid #2072be;
    color: #fff;
    font-family: unset;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 32px 16px 32px;
    border-radius: 50px;
    min-width: 150px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    box-shadow: 0 3px 6px -3px rgb(29 20 3 / 70%), 0 3px 70px 3px rgb(29 20 3 / 20%);

    &.small {
        box-shadow: 0 1px 2px 0 rgb(29 20 3 / 80%), 0 3px 17px 6px rgb(29 20 3 / 10%);
        padding: 8px 12px;
        font-size: .75rem;
        min-width: unset;
    }
}