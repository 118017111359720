@import '../../styles/colors';

.textarea {
    border: 2px solid $grey-500;
    background: #fff;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 6px;
    padding: 16px;
    font-family: unset;
    font-size: 18px;
    color: $grey-700;
    outline: none;
    transition: all .5s;

    &:focus {
        color: $grey-900;
        border: 2px solid $grey-800;
        box-shadow: 0 3px 5px 0 $grey-400, 0 7px 30px 7px $grey-300;
    }
    
    &::placeholder {
        color: $grey-700;
        opacity: 0.4;
    }
    
    &.error {
        border-color: $error-900
    }
}
