.form {
    padding: 24px;

    h1 {
        color: #09412D;
        font-size: 36px;
    }

    label {
        margin-bottom: 12px;
        display: inline-block;
        color: #24483B;
        font-weight: 600;
    }

    .control {
        margin-bottom: 16px;
    }

    .actions {
        margin-top: 24px;
        text-align: center;
    }
}